import React, { useEffect, useState } from "react";
import { MenuButton } from "../MenuButton/MenuButton";
import { auth } from "firebase.js";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { TsjatLogo } from "stories/atoms/TsjatLogo/TsjatLogo";
import {
  MyProfileButton,
  RegisterButton,
  SignInButton,
} from "stories/atoms/ReusableButtons/ReusableButtons";
import getSenderName from "scripts/firestore/getSenderName";

export const Navbar = () => {
  const navigate = useNavigate();

  const [user] = useAuthState(auth);
  const [username, setUsername] = useState("Loading...");

  useEffect(() => {
    const fetchSenderInfo = async () => {
      if (user) {
        //logged in
        setUsername(await getSenderName(user));
      } else {
      }
    };

    fetchSenderInfo();
  }, [user]);

  return (
    <nav className="flex justify-between p-4 bg-brand-bg shadow-md">
      <div>
        <div
          onClick={() => navigate("/")}
          onKeyDown={() => navigate("/")}
          className="h-12 flex hover:brightness-75 hover:cursor-pointer active:brightness-50"
        >
          <TsjatLogo />
        </div>
      </div>
      <div className="flex">
        <div className="flex text-white space-x-4 2sm:hidden">
          {user ? (
            <MyProfileButton username={username} />
          ) : (
            <>
              <RegisterButton />
              <SignInButton />
            </>
          )}
        </div>
        <div className="ml-4" />
        <MenuButton />
      </div>
    </nav>
  );
};
