export const addLastChatroomsVisited = (chatroom) => {
  const existingArray =
    JSON.parse(localStorage.getItem("lastChatroomsVisited")) || [];

  if (existingArray.includes(chatroom)) return;

  // Add the new string to the beginning of the array
  existingArray.unshift(chatroom);

  // Ensure the array length does not exceed the maximum
  if (existingArray.length > 10) {
    existingArray.pop(); // Remove the last entry
  }

  // Save the updated array back to the cookie
  localStorage.setItem("lastChatroomsVisited", JSON.stringify(existingArray));
};

export const getLastChatroomsVisited = () => {
  const existingArray =
    JSON.parse(localStorage.getItem("lastChatroomsVisited")) || [];

  return existingArray;
};
