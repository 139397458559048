import { db } from "firebase.js";
import { collection, onSnapshot, query } from "firebase/firestore";

export const collectChatroomMessageAmountFromFirebase = (callback) => {
  const chatroomsCollectionRef = collection(db, "chatroombrowse");

  const chatroomsQuery = query(chatroomsCollectionRef);

  const unsubscribe = onSnapshot(chatroomsQuery, (querySnapshot) => {
    const chatrooms = [];
    // biome-ignore lint/complexity/noForEach: <explanation>
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      chatrooms.push(data);
    });

    callback(chatrooms);
  });

  return unsubscribe;
};
