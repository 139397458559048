import { db } from "firebase.js";
import { collection, getDocs } from "firebase/firestore";

export const isUsernameTakenFromFirestore = async (username) => {
  const usersRef = collection(db, "users");

  try {
    const querySnapshot = await getDocs(usersRef);
    const usernamesArray = [];
    // biome-ignore lint/complexity/noForEach: <explanation>
    querySnapshot.forEach((doc) => {
      const usernameData = doc.data().username;
      if (usernameData) {
        usernamesArray.push(usernameData);
      }
    });
    return usernamesArray.includes(username);
  } catch (error) {
    console.error("Error fetching documents");
  }
};
