import { db, realtimeDb } from "firebase.js";
import { get, ref } from "firebase/database";
import {
  deleteDoc,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";

const getChatroomMessagesAmountFromRTDB = async (callback) => {
  const chatroomsRef = ref(realtimeDb, "chatrooms");
  const chatroomsSnapshot = await get(chatroomsRef);

  if (chatroomsSnapshot.exists()) {
    const chatroomMessageAmount = [];

    for (const chatroomKey in chatroomsSnapshot.val()) {
      const chatroomData = chatroomsSnapshot.val()[chatroomKey];

      const chatroom = chatroomKey;
      const messages = Object.keys(chatroomData.messages).length;

      !chatroom.includes("testings") &&
        chatroomMessageAmount.push({ chatroom, messages });
    }
    callback(chatroomMessageAmount);
  } else {
    console.log("No chatrooms found.");
  }
};

const isOlderThanTwoHours = (timestamp) => {
  const now = Date.now();
  const firestoreTimestampMillis =
    timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6;
  const diff = now - firestoreTimestampMillis;
  const twoHours = 2 * 60 * 60 * 1000;
  // const oneMinute = 60 * 1000; // 60 seconds * 1000 milliseconds

  return diff > twoHours;
};

const isTimeToUpdateChatroomMessageAmount = async () => {
  const userRef = doc(db, "chatroombrowse", "chatrooms");
  try {
    const docSnapshot = await getDoc(userRef);

    if (docSnapshot.exists()) {
      const lastUpdated = docSnapshot.data().last_updated;
      return isOlderThanTwoHours(lastUpdated);
    }
    return true;
  } catch (error) {
    // Handle any errors (e.g., network issues, permissions)
    console.error("Error fetching chatroom data");
    return false;
  }
};

const deleteChatroomBrowse = () => {
  const chatroomsRef = doc(db, "chatroombrowse", "chatrooms");

  deleteDoc(chatroomsRef)
    .then(() => {
      //deleted
    })
    .catch(() => {
      console.error("Error deleting chatrooms document");
    });
};

const addChatroomBrowse = async () => {
  deleteChatroomBrowse();

  const chatroomsDocRef = doc(db, "chatroombrowse", "chatrooms");

  const chatroomMessagesArray = await new Promise((resolve) => {
    getChatroomMessagesAmountFromRTDB(resolve);
  });

  chatroomMessagesArray.sort((a, b) => b.messages - a.messages);

  const newChatroomData = {
    last_updated: serverTimestamp(),
  };

  chatroomMessagesArray.splice(50);

  // biome-ignore lint/complexity/noForEach: <explanation>
  chatroomMessagesArray.forEach(({ chatroom, messages }) => {
    newChatroomData[chatroom] = messages.toString();
  });

  setDoc(chatroomsDocRef, newChatroomData);
};

export const checkIfUpdateChatroomMessageAmount = async () => {
  if (await isTimeToUpdateChatroomMessageAmount()) {
    deleteChatroomBrowse();
    addChatroomBrowse();
  }
};
