import classNames from "classnames";
import React from "react";

export const BrowseCard = ({
  number = "999",
  children = "keyword",
  antiGrow = false,
  onClick = () => {},
}) => {
  return (
    <div
      onClick={() => onClick()}
      onKeyDown={() => onClick()}
      className={classNames(
        { "grow-0": antiGrow },
        "hover:cursor-pointer grow justify-center m-1 flex-wrap select-none hover:brightness-90 active:brightness-75 flex rounded-xl p-3 bg-brand-darkbg shadow-md space-x-2"
      )}
    >
      <p className="text-brand-text">{children}</p>

      <div className="px-3 text-white shadow-md bg-brand-2contrast rounded-md">
        <p className="drop-shadow-text">{number}</p>
      </div>
    </div>
  );
};
