function hashString(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

function SeededRNG(seed) {
  this.seed = hashString(seed.toString());
}

SeededRNG.prototype.next = function () {
  this.seed = (this.seed * 9301 + 49297) % 233280; // These are arbitrary parameters for the LCG
  return this.seed / 233280;
};

export const seedNumber = (seed) => {
  const rng = new SeededRNG(seed); // Initialize with a seed

  const rawNumber = Math.abs(rng.next() * 10000000000);
  return rawNumber.toString().substring(0, 6);
};

// Usage:
