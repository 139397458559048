import { realtimeDb } from "firebase.js";
import { ref, remove } from "firebase/database";
import { getUserdataFromFirestore } from "scripts/firestore/GetUserdataFromFirestore";

export const deleteChatroomMessage = async (userUid, chatroom, key) => {
  const chatroomRef = ref(realtimeDb, `chatrooms/${chatroom}/messages/${key}`);

  const userData = await getUserdataFromFirestore(userUid);

  if (userData.rank !== "admin" && userData.rank !== "mod") {
    return;
  }
  // Remove the document
  remove(chatroomRef)
    .then(() => {
      //Success
    })
    .catch(() => {
      console.log("Error deleting document");
    });
};
