/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { collectChatroomMessageAmountFromFirebase } from "scripts/firestore/collectChatroomMessageAmountFromFirebase";
import { checkIfUpdateChatroomMessageAmount } from "scripts/firestore/updateChatroomMessageAmount";
import { BrowseCard } from "stories/atoms/BrowseCard/BrowseCard";
import { SortButton } from "stories/atoms/SortButton/SortButton";

export const ChatroomBrowse = () => {
  const [isSortPopular, setSortPopular] = useState(true);

  const [chatrooms, setChatrooms] = useState([]);

  const [sortedChatrooms, setSortedChatrooms] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    checkIfUpdateChatroomMessageAmount();

    const fetchData = async () => {
      try {
        await collectChatroomMessageAmountFromFirebase(setChatrooms); //gets it from firestore
      } catch (error) {
        console.error("Error fetching chatroom data");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const array = [];

    chatrooms.map((chatroom) => {
      Object.entries(chatroom).map(([field, value]) => {
        if (field !== "last_updated") {
          array.push({ field, value });
        }
      });
    });

    if (isSortPopular) {
      array.sort((a, b) => b.value - a.value);
    } else {
      array.sort((a, b) => a.value - b.value);
    }

    setSortedChatrooms(array);
  }, [chatrooms, isSortPopular]);

  // const sorted = entries.sort((a, b) => a[1] - b[1]);

  return (
    <div className="flex flex-col bg-brand-bg p-8 space-y-4">
      <div className="flex items-center space-x-4 sm:flex-col sm:items-start sm:space-x-0 sm:space-y-4">
        <div className="flex text-brand-text font-semibold text-4xl">
          Browse by popularity
        </div>

        {isSortPopular ? (
          <SortButton
            onClick={() => setSortPopular(!isSortPopular)}
            icon={<MdArrowUpward />}
          >
            Most popular
          </SortButton>
        ) : (
          <SortButton
            onClick={() => setSortPopular(!isSortPopular)}
            icon={<MdArrowDownward />}
          >
            Less popular
          </SortButton>
        )}
      </div>
      {chatrooms.length === 0 && (
        <div className="w-full h-20 flex items-center justify-center">
          <div className="absolute w-20 h-20 bg-loading bg-cover" />
        </div>
      )}

      <div className="flex flex-wrap">
        {sortedChatrooms.map((element) => (
          <BrowseCard
            onClick={() => navigate(`/chatroom/${element.field}`)}
            key={element.field}
            number={element.value}
          >
            {element.field}
          </BrowseCard>
        ))}
      </div>
    </div>
  );
};
