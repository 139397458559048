import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { convertToKeyword } from "scripts/convertToKeyword";
import { Button } from "stories/atoms/Button/Button";
import { InputText } from "stories/atoms/InputText/InputText";

export const ChatroomSearch = () => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");

  const onNavigate = () => {
    if (keyword.trim() === "") navigate("/chatroom/chat");
    if (keyword.length > 30) {
      return;
    }
    navigate(`/chatroom/${convertToKeyword(keyword)}`);
    setKeyword("");
  };

  return (
    <section className="flex flex-row items-center justify-center min-h-40">
      <div className="flex p-4 w-[550px] flex-col space-y-3">
        <div className="text-5xl font-bold text-brand-contrast text-center">
          Join another chatroom
        </div>
        <div className="flex 4sm:flex-col 4sm:space-x-0 4sm:space-y-1.5 space-x-1.5">
          <div className="grow">
            <InputText
              onChange={(input) => setKeyword(input)}
              value={keyword}
              onEnter={onNavigate}
              type={keyword.length > 30 ? "error" : "browse"}
              leftText={keyword.length > 30 && "Too long!"}
              rightText={`${keyword.length}/30`}
              placeholder="Chat keyword"
            />
          </div>
          <div>
            <Button onClick={() => onNavigate()} expand>
              Enter
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};
