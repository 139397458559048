import { realtimeDb } from "firebase.js";
import { ref, push, set, serverTimestamp } from "firebase/database";

const sendChatMessage = async (
  chatroomName,
  message,
  senderName,
  senderRank,
  onError = (error) => {}
) => {
  try {
    if (message.trim() === "") return;

    const messagesRef = ref(realtimeDb, `chatrooms/${chatroomName}/messages`);
    const newMessageRef = push(messagesRef);

    await set(newMessageRef, {
      message: message,
      sender: senderName,
      rank: senderRank,
      datesent: serverTimestamp(),
    });
  } catch (error) {
    onError(error);
  }
};

export default sendChatMessage;
