import React from "react";

export const TsjatLogo = () => {
  return (
    <img
      className="max-w-full max-h-full object-cover select-none pointer-events-none"
      src={`${process.env.PUBLIC_URL}/logocolor.png`}
      alt=""
    />
  );
};
