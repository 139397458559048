import React from "react";
import { MdMessage } from "react-icons/md";

export const RecentChatroomButton = ({ children = "Button", onClick }) => {
  return (
    <button
      type="button"
      onClick={() => onClick()}
      className="flex justify-center items-center space-x-1 font-medium font-roboto !bg-brand-2contrast hover:brightness-75 active:brightness-50 text-nowrap px-2 grow max-w-52 min-w-max text-black rounded-lg"
    >
      <MdMessage />
      <p>{children}</p>
    </button>
  );
};
