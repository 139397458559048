export function convertDate(timestamp) {
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  }).format(timestamp);

  const now = Date.now();
  const diff = now - timestamp;
  const oneDay = 24 * 60 * 60 * 1000;

  try {
    if (diff < oneDay) {
      // Within the last 24 hours
      return `Today at ${new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      }).format(timestamp)}`;
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else if (diff < 2 * oneDay) {
      // Yesterday
      return `Yesterday at ${new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      }).format(timestamp)}`;
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else {
      // Older date (display full date)
      return formattedDate;
    }
  } catch (error) {
    console.log("Unknown date");
    return "Unknown date";
  }
}
