import React from "react";
import { ChatroomBrowse } from "stories/molecules/ChatroomBrowse/ChatroomBrowse";
import { ChatroomSearch } from "stories/molecules/ChatroomSearch/ChatroomSearch";
import { Chatroom } from "../Chatroom/Chatroom";

export const Home = () => {
  return (
    <div>
      <Chatroom />
      <ChatroomSearch />
      <ChatroomBrowse />
    </div>
  );
};
