import React from "react";
import { Button } from "../Button/Button";
import { signOutUser } from "scripts/signOutUser";
import {
  MdAccountCircle,
  MdHelpCenter,
  MdLogin,
  MdLogout,
  MdPersonAddAlt1,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";

export const SignOutButton = () => {
  return (
    <Button onClick={signOutUser} expand icon={<MdLogout />} color="red">
      Sign Out
    </Button>
  );
};

export const RegisterButton = () => {
  const navigate = useNavigate();

  return (
    <Button
      expand
      onClick={() => navigate("/register")}
      icon={<MdPersonAddAlt1 />}
    >
      Register
    </Button>
  );
};
export const SignInButton = () => {
  const navigate = useNavigate();

  return (
    <Button expand onClick={() => navigate("/sign_in")} icon={<MdLogin />}>
      Sign In
    </Button>
  );
};

export const MyProfileButton = ({ username = "My Profile" }) => {
  const navigate = useNavigate();

  return (
    <Button
      expand
      onClick={() => navigate("/my_profile")}
      icon={<MdAccountCircle />}
    >
      {username}
    </Button>
  );
};
export const HelpButton = () => {
  const navigate = useNavigate();

  return (
    <Button expand onClick={() => navigate("/help")} icon={<MdHelpCenter />}>
      Help
    </Button>
  );
};
