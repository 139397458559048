import { auth } from "firebase.js";
import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { MdMenu } from "react-icons/md";
import { DropdownMenu } from "stories/atoms/DropdownMenu/DropdownMenu";
import {
  HelpButton,
  MyProfileButton,
  RegisterButton,
  SignInButton,
  SignOutButton,
} from "stories/atoms/ReusableButtons/ReusableButtons";

export const MenuButton = () => {
  const [showMenu, setShowMenu] = useState(false);

  const [user] = useAuthState(auth);

  return (
    <>
      {showMenu && (
        // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
        <div
          onClick={() => setShowMenu(false)}
          className="absolute w-full h-screen top-0 left-0 z-30"
        />
      )}
      <div
        onClick={() => {
          setShowMenu(!showMenu);
        }}
        onKeyDown={() => {
          setShowMenu(!showMenu);
        }}
        className="flex h-12 items-center hover:brightness-75 hover:cursor-pointer"
      >
        <div className="text-4xl text-brand-text">
          <MdMenu />
        </div>
      </div>
      {showMenu && (
        <div className="absolute right-0 flex justify-center items-center mt-16 z-40 shadow-md">
          <DropdownMenu>
            {user ? (
              <div className="space-y-1">
                <SignOutButton />
                <div className="hidden 2sm:block">
                  <MyProfileButton />
                </div>
              </div>
            ) : (
              <div className="space-y-1 hidden 2sm:block">
                <RegisterButton />
                <SignInButton />
              </div>
            )}

            <HelpButton />
          </DropdownMenu>
        </div>
      )}
    </>
  );
};
