export function getGuestId() {
  let guestId = localStorage.getItem("guestId");

  if (!guestId) {
    guestId = Math.floor(Math.random() * 1000000).toString();
    localStorage.setItem("guestId", guestId);
  }

  return guestId;
}
