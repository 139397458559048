import React, { useEffect, useState } from "react";
import { Button } from "stories/atoms/Button/Button";
import { InputText } from "stories/atoms/InputText/InputText";
import { auth, db } from "firebase.js";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, CardHeader } from "stories/molecules/Card/Card";
import { isUsernameTakenFromFirestore } from "scripts/firestore/getAllUsernamesArrayFromFirestore";

export const Register = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [usernameError, setUsernameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  useEffect(() => {
    if (user) navigate("/");
  });

  const checkValidUsername = async (username) => {
    const usernameTaken = await isUsernameTakenFromFirestore(username);
    if (usernameTaken) {
      setUsernameError("Username is already taken!");
      return false;
    }

    if (username.length > 16) {
      setUsernameError("Username is too long!");
      return false;
    }

    if (username.length < 3) {
      setUsernameError("Username is too short!");
      return false;
    }

    const regex = /^[A-Za-z0-9]+$/;
    if (!regex.test(username)) {
      setUsernameError("Invalid username (A-Z, 0-9)");
      return false;
    }

    return true;
  };

  const Register = async () => {
    setUsernameError("");
    setEmailError("");
    setPasswordError("");

    if (!(await checkValidUsername(username))) return;

    createUserWithEmailAndPassword(auth, email, password)
      .then(async (user) => {
        // Success, now add user info to firestore
        const userRef = doc(db, "users", user.user.uid);
        await setDoc(userRef, {
          username: username,
          rank: "",
        });
      })
      .catch((error) => {
        if (error.message.includes("auth/invalid-email")) {
          setEmailError("Invalid email");
        }
        if (error.message.includes("auth/email-already-in-use")) {
          setEmailError("Email already in use!");
        }
        if (error.message.includes("auth/weak-password")) {
          setPasswordError("Password should be at least 6 characters");
        }
      });
  };

  return (
    <>
      <Card>
        <CardHeader>Register</CardHeader>
        <CardContent className="space-y-4">
          <InputText
            attType="email"
            icon="notepad"
            placeholder="email@email.com"
            label="Email Address"
            leftText={emailError && emailError}
            type={emailError ? "error" : "browse"}
            onChange={(value) => setEmail(value)}
            value={email}
          />
          <InputText
            attType="password"
            icon="notepad"
            placeholder="••••••••••••••••"
            label="Password"
            leftText={passwordError && passwordError}
            type={passwordError ? "error" : "browse"}
            onChange={(value) => setPassword(value)}
            value={password}
          />
          <InputText
            attType="text"
            icon="notepad"
            placeholder="Username123"
            label="Username"
            leftText={usernameError && usernameError}
            type={usernameError ? "error" : "browse"}
            onChange={(value) => setUsername(value)}
            value={username}
          />
          <Button onClick={Register} expand>
            Register
          </Button>
        </CardContent>
      </Card>
    </>
  );
};
