import React from "react";
import { Badge } from "../Badge/Badge";
import classNames from "classnames";
import { MdDelete, MdReply } from "react-icons/md";

export const ChatMessage = ({
  username = "Guest",
  children = "Message",
  reply = false,
  badge = "",
  date = "Unknown date",
  addDeleteButton = false,
  onDelete = () => {},
  onReply = () => {},
}) => {
  return (
    <>
      <div className="relative group">
        <div
          className={classNames(
            "font-roboto bg-brand-2bg p-2 border-t-2 border-brand-contrast flex space-x-4 text-xl text-white",
            { "bg-brand-bg": reply }
          )}
        >
          <span className="overflow-hidden text-ellipsis">
            <div
              /*set to inline-flex for compact design*/ className="flex flex-wrap 2sm:flex font-extrabold drop-shadow-text"
            >
              {badge && <Badge rank={badge} />}
              {username}
              <div className="mr-2" />
              {reply && (
                <>
                  <div className="bg-brand-2contrast text-white flex font-bold text-sm w-max p-1 rounded-full px-2 mb-1">
                    <div className="text-xl drop-shadow-text">
                      <MdReply />
                    </div>
                    <div className="drop-shadow-text">You were mentioned</div>
                  </div>
                  <div className="mr-2" />
                </>
              )}
              <div className="text-nowrap flex items-center font-normal text-center text-gray-400 text-sm">
                {date}
              </div>
            </div>

            <div className="text-brand-text inline-flex drop-shadow-text">
              {children}
            </div>
          </span>
        </div>
        <div className="absolute hidden group-hover:flex 2sm:flex text-3xl text-white p-1 top-0 right-0">
          <div
            onClick={() => onReply()}
            onKeyDown={() => onReply()}
            className="hover:cursor-pointer hover:brightness-75 active:brightness-50"
          >
            <MdReply />
          </div>
          {addDeleteButton && (
            <div
              className="hover:cursor-pointer hover:brightness-75 active:brightness-50"
              onClick={() => onDelete()}
              onKeyDown={() => onDelete()}
            >
              <MdDelete />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
