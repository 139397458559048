import classNames from "classnames";
import React from "react";

export const Button = ({
  icon = null,
  children = "Button",
  color = "blue",
  size = "medium",
  onClick = () => {},
  expand = false,
  disabled = false,
  className = "",
}) => {
  const colorLeft = classNames({
    "bg-red-700": color === "red",
    "bg-brand-2contrast": color === "blue",
    "bg-yellow-600": color === "yellow",
    "bg-green-600": color === "green",
  });

  const colorRight = classNames({
    "bg-red-800": color === "red",
    "bg-sky-700": color === "blue",
    "bg-yellow-700": color === "yellow",
    "bg-green-700": color === "green",
  });

  return (
    <div
      onClick={() => onClick()}
      onKeyDown={() => onClick()}
      className={classNames(
        className,
        {
          "max-w-max": !expand,
          "pointer-events-none": disabled,
        },
        `flex hover:brightness-90 hover:cursor-pointer active:brightness-75 text-white shadow-md rounded overflow-hidden ${colorLeft}`
      )}
    >
      <div
        className={classNames(
          { "!p-1 text-sm": size === "small" },
          `${
            expand && "w-full"
          } flex p-3 select-none min-w-24 font-roboto font-bold justify-center drop-shadow-text`
        )}
      >
        {children}
      </div>
      {icon !== null && (
        <div
          className={`flex min-w-12 text-xl justify-center items-center ${colorRight}`}
        >
          {icon}
        </div>
      )}
    </div>
  );
};
