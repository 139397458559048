import React, { useEffect, useState } from "react";
import { Button } from "stories/atoms/Button/Button";
import { InputText } from "stories/atoms/InputText/InputText";
import { auth } from "firebase.js";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { Card, CardContent, CardHeader } from "stories/molecules/Card/Card";

export const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const [user] = useAuthState(auth);

  useEffect(() => {
    if (user) navigate("/");
  });

  const [error, setError] = useState("");

  const SignIn = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((user) => {
        //Success with login
        setError("");
      })
      .catch((error) => {
        setError("Invalid credentials");
      });
  };

  return (
    <>
      <Card>
        <CardHeader>Sign In</CardHeader>
        <CardContent className="space-y-4">
          <InputText
            attType="email"
            icon="notepad"
            placeholder="email@email.com"
            label="Email Address"
            onChange={(value) => setEmail(value)}
            type={error && "error"}
            onEnter={SignIn}
            value={email}
          />
          <InputText
            attType="password"
            icon="notepad"
            placeholder="••••••••••••••••"
            label="Password"
            onEnter={SignIn}
            onChange={(value) => setPassword(value)}
            type={error && "error"}
            value={password}
          />
          {error && <p className="text-red-600">Invalid user credentials</p>}
          <Button onClick={SignIn} expand>
            Sign In
          </Button>
        </CardContent>
      </Card>
    </>
  );
};
