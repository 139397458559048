import React, { useState } from "react";
import { Button } from "stories/atoms/Button/Button";
import { InputText } from "stories/atoms/InputText/InputText";
import { auth } from "firebase.js";
import { sendPasswordResetEmail } from "firebase/auth";
import { Card, CardContent, CardHeader } from "stories/molecules/Card/Card";

export const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailSuccess, setEmailSuccess] = useState("");

  const ResetPasword = () => {
    if (emailError || emailSuccess) return;
    setEmailError("");
    setEmailSuccess("");

    const actionCodeSettings = {
      url: "https://tsjat.com",
      handleCodeInApp: false,
    };

    sendPasswordResetEmail(auth, email, actionCodeSettings)
      .then(() => {
        setEmailSuccess(
          "If an account with this email exists it will be sent."
        );
      })
      .catch((error) => {
        console.log("Error sending email", error);
        if (error.message.includes("auth/too-many-requests")) {
          setEmailError("Too many requests, please wait");
        }
        if (error.message.includes("auth/quota-exceeded")) {
          setEmailError("Too many requests, please wait");
        }
      });
  };

  return (
    <>
      <Card>
        <CardHeader>Reset password</CardHeader>
        <CardContent className="space-y-4">
          <InputText
            attType="email"
            icon="notepad"
            placeholder="email@email.com"
            label="Email Address"
            onChange={(value) => setEmail(value)}
            type="browse"
            onEnter={ResetPasword}
            value={email}
          />
          <Button onClick={ResetPasword} expand>
            Reset
          </Button>
          {emailError && (
            <div className="text-red-500 font-bold drop-shadow-text">
              {emailError}
            </div>
          )}
          {emailSuccess && (
            <div className="text-green-500 font-bold drop-shadow-text">
              {emailSuccess}
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
};
