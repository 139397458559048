import { realtimeDb } from "firebase.js";
import { ref, query, onValue, off, limitToLast } from "firebase/database";

const getChatMessages = (
  chatKeyword,
  loadedMessagesAmount,
  setChatMessages,
  setIsPageLoaded
) => {
  const messagesRef = ref(realtimeDb, `chatrooms/${chatKeyword}/messages`);
  const dbQuery = query(messagesRef, limitToLast(loadedMessagesAmount));

  const handleValueChange = (snapshot) => {
    const messages = [];
    // biome-ignore lint/complexity/noForEach: <explanation>
    snapshot.forEach((childSnapshot) => {
      const messageData = childSnapshot.val();
      messages.push({ ...messageData, key: childSnapshot.key });
    });
    setChatMessages(messages);
    setIsPageLoaded(true);
  };

  onValue(dbQuery, handleValueChange);

  return () => {
    off(dbQuery, "value", handleValueChange);
  };
};

export default getChatMessages;
