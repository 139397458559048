import { auth } from "firebase.js";
import { signOut } from "firebase/auth";

export const signOutUser = () => {
  signOut(auth)
    .then(() => {
      //signout successful
    })
    .catch((error) => console.log("Error signing out"));
};
