import { db } from "firebase.js";
import { doc, getDoc } from "firebase/firestore";

export const getUserdataFromFirestore = async (uid) => {
  const userRef = doc(db, "users", uid);
  const docSnapshot = await getDoc(userRef);

  if (docSnapshot.exists()) {
    const userData = docSnapshot.data();
    return userData;
  }
  console.log("ERROR: Can't find user");
};
