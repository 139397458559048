import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
  const navigate = useNavigate();

  const sectionClass =
    "[&>button]:text-left flex sm:items-center flex-col [&>h1]:font-extrabold [&>h1]:mb-2";

  return (
    <footer>
      <div
        className={classNames(
          "flex sm:flex-col sm:space-x-0 sm:space-y-6 sm:text-center sm:p-4 sm:py-10 text-gray-100 bg-brand-darkbg justify-evenly p-20 space-x-12 text-sm font-roboto"
        )}
      >
        <div className={sectionClass}>
          <h1>CONTACT</h1>
          <button type="button">contact@tsjat.com</button>
        </div>
        <div className={sectionClass}>
          <h1>LINKS</h1>
          <button onClick={() => navigate("register")} type="button">
            Register
          </button>
          <button onClick={() => navigate("sign_in")} type="button">
            Sign In
          </button>
          <button onClick={() => navigate("help")} type="button">
            Help
          </button>
        </div>
        <div className={sectionClass}>
          <h1>SOCIALS</h1>
          <a
            href="https://twitter.com/Tsjat_com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="bg-twitter bg-cover w-6 h-6" />
          </a>
          <a
            href="https://youtube.com/@tsjat_com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="bg-youtube bg-cover w-6 h-6" />
          </a>
        </div>
      </div>
    </footer>
  );
};
