import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Footer } from "stories/molecules/Footer/Footer";
import { Navbar } from "stories/molecules/Navbar/Navbar";
import { ForgotPassword } from "stories/pages/ForgotPassword/ForgotPassword";
import { Help } from "stories/pages/Help/Help";
import { Home } from "stories/pages/Home/Home";
import { MyProfile } from "stories/pages/MyProfile/MyProfile";
import { PageNotFound } from "stories/pages/PageNotFound/PageNotFound";
import { Register } from "stories/pages/Register/Register";
import { SignIn } from "stories/pages/SignIn/SignIn";

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/chatroom/:chatKeyword" element={<Home />} />
          <Route path="/chatroom" element={<Navigate to="/chatroom/chat" />} />
          <Route path="/register" element={<Register />} />
          <Route path="/sign_in" element={<SignIn />} />
          <Route path="/my_profile" element={<MyProfile />} />
          <Route path="/help" element={<Help />} />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
