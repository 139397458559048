import React from "react";
import { MdNote } from "react-icons/md";
import { Button } from "stories/atoms/Button/Button";
import { Card, CardContent, CardHeader } from "stories/molecules/Card/Card";

export const PageNotFound = () => {
  return (
    <Card>
      <CardHeader className="items-center">
        <MdNote className="mr-2" />
        Page not found
      </CardHeader>
      <CardContent className="space-y-2">
        <div className="text-lg text-gray-200">
          The page you are requesting doesn't exist. It might have been deleted
          or moved to a new location.
        </div>
        <Button onClick={() => window.history.back()} expand>
          Go back
        </Button>
      </CardContent>
    </Card>
  );
};
