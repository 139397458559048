import React, { useState } from "react";
import { Button } from "../Button/Button";

export const CountdownButton = ({
  onClick = () => {},
  size = "small",
  children = "Load more",
}) => {
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);

  const startCountdown = () => {
    setButtonDisabled(true);
    setCountdown(3);

    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(intervalId);
      setButtonDisabled(false);
    }, 3000);
  };
  return (
    // @ts-ignore
    <Button
      disabled={isButtonDisabled}
      onClick={() => {
        startCountdown();
        onClick();
      }}
      size={size}
    >
      {children} {isButtonDisabled && `${countdown}s`}
    </Button>
  );
};
