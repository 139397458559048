import classNames from "classnames";
import React from "react";

export const SortButton = ({
  size = "normal",
  icon = null,
  onClick = () => {},
  children = "Popular",
}) => {
  return (
    <div
      onClick={() => onClick()}
      onKeyDown={() => onClick()}
      className={classNames(
        {
          "text-2xl p-2 px-5": size === "large",
          "p-1 px-3": size === "normal",
          "text-xs px-2 min-w-max": size === "small",
        },
        "flex items-center h-max hover:cursor-pointer min-w-[150px] justify-center text-nowrap select-none hover:brightness-90 active:brightness-75 w-max bg-brand-text font-bold text-brand-darkbg rounded-full"
      )}
    >
      {children}
      {icon !== null && (
        <div className={classNames("text-xl pl-1")}>{icon}</div>
      )}
    </div>
  );
};
