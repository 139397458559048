import React, { useEffect, useState } from "react";
import { Button } from "stories/atoms/Button/Button";
import { InputText } from "stories/atoms/InputText/InputText";
import { auth } from "firebase.js";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendEmailVerification,
  updatePassword,
} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CardText,
  CardTitle,
} from "stories/molecules/Card/Card";
import { MdAccountCircle, MdCheckBox, MdEmail, MdLock } from "react-icons/md";
import { getUserdataFromFirestore } from "scripts/firestore/GetUserdataFromFirestore";
import { Badge } from "stories/atoms/Badge/Badge";

export const MyProfile = () => {
  const navigate = useNavigate();

  const [user] = useAuthState(auth);

  const [verifiedEmail, setVerifiedEmail] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [emailSuccess, setEmailSuccess] = useState("");

  useEffect(() => {
    if (!user) navigate("/");

    if (user?.emailVerified) setVerifiedEmail(true);
  }, [user, navigate]);

  const sendVerificationEmail = async () => {
    setEmailError("");
    setEmailSuccess("");

    if (user) {
      const actionCodeSettings = {
        url: "https://tsjat.com",
        handleCodeInApp: false,
      };

      try {
        await sendEmailVerification(user, actionCodeSettings);
        setEmailSuccess("Verification email has been sent");
      } catch (error) {
        if (error.message.includes("auth/too-many-requests")) {
          setEmailError("Please try again later");
        }
      }
    }
  };

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [passwordError, setPasswordError] = useState("");
  const [passwordSuccess, setPasswordSuccess] = useState("");

  const handleChangePassword = async () => {
    setPasswordSuccess("");
    if (passwordError) return;

    try {
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );
      await reauthenticateWithCredential(user, credential);

      await updatePassword(user, newPassword);

      setPasswordSuccess("Password changed successfully");
    } catch (error) {
      if (error.message.includes("auth/invalid-credential")) {
        setPasswordError("Invalid credentials!");
      }

      if (error.message.includes("auth/too-many-requests")) {
        setPasswordError("Too many requests, please wait and try again");
      }
    }
  };

  useEffect(() => {
    setPasswordError("");
    setPasswordSuccess("");

    if (currentPassword.length === 0 && newPassword.length === 0) return;

    if (newPassword === currentPassword) {
      setPasswordError("Passwords can't match");
    }

    if (newPassword.length < 6) {
      setPasswordError("New password must be 6+ characters.");
    }
  }, [newPassword, currentPassword]);

  const [userRank, setUserRank] = useState("");

  useEffect(() => {
    const fetchSenderInfo = async () => {
      if (user) {
        const userData = await getUserdataFromFirestore(user.uid);
        setUserRank(userData.rank);
      }
    };

    fetchSenderInfo();
  }, [user]);

  return (
    <Card>
      <CardHeader className="items-center space-x-2">
        <div>
          <MdAccountCircle />
        </div>
        <div>My Profile</div>
      </CardHeader>
      <CardContent className="space-y-1">
        <CardTitle>Rank</CardTitle>
        <CardText className="flex items-center">
          {userRank ? (
            <>
              You are currently <Badge className="ml-1" rank={userRank} /> rank.
            </>
          ) : (
            <>You do not have a rank currently.</>
          )}
        </CardText>
      </CardContent>
      {verifiedEmail ? (
        //Verified email
        <CardContent className="space-y-1">
          <CardTitle>Email</CardTitle>
          <CardText className="flex items-center">
            Your email is verified <MdCheckBox className="ml-1" />
          </CardText>
        </CardContent>
      ) : (
        //Unverified email
        <CardContent className="space-y-1">
          <CardTitle>Email</CardTitle>
          <CardText>Your email is not verified</CardText>
          {emailSuccess ? (
            <Button
              icon={<MdEmail />}
              onClick={() => sendVerificationEmail()}
              color="green"
              expand
            >
              {emailSuccess}
            </Button>
          ) : emailError ? (
            <Button
              icon={<MdEmail />}
              onClick={() => sendVerificationEmail()}
              color="red"
              expand
            >
              {emailError}
            </Button>
          ) : (
            <Button
              icon={<MdEmail />}
              onClick={() => sendVerificationEmail()}
              color="green"
              expand
            >
              Send a verification email
            </Button>
          )}
        </CardContent>
      )}
      <CardContent className="space-y-2">
        <div className="flex text-4xl font-bold items-center drop-shadow-text">
          Change password
        </div>
        <div
          onClick={() => navigate("/forgot_password")}
          onKeyDown={() => navigate("/forgot_password")}
          className="drop-shadow-text text-xl text-gray-200 hover:underline select-none hover:text-white hover:cursor-pointer active:brightness-75"
        >
          Click here to reset password
        </div>
        {passwordError && (
          <div className="text-red-500 bg-brand-3bg max-w-max rounded-lg px-2 font-bold">
            <div className="drop-shadow-text">{passwordError}</div>
          </div>
        )}
        {passwordSuccess && (
          <div className="text-green-500 bg-brand-3bg max-w-max rounded-lg px-2 font-bold">
            <div className="drop-shadow-text">{passwordSuccess}</div>
          </div>
        )}
        <InputText
          icon="notepad"
          label="Current password"
          onChange={(value) => setCurrentPassword(value)}
          value={currentPassword}
          type="browse"
          placeholder="••••••••••••••••••••"
          attType="password"
        />
        <InputText
          icon="notepad"
          label="New password"
          type="browse"
          onChange={(value) => setNewPassword(value)}
          value={newPassword}
          placeholder="••••••••••••••••••••"
          attType="password"
        />
        <Button
          onClick={() => handleChangePassword()}
          icon={<MdLock />}
          color="green"
          expand
        >
          Submit
        </Button>
      </CardContent>
    </Card>
  );
};
