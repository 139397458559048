import React from "react";
import { Button } from "stories/atoms/Button/Button";
import {
  Card,
  CardContent,
  CardHeader,
  CardText,
  CardTitle,
} from "stories/molecules/Card/Card";

export const Help = () => {
  return (
    <>
      <Card>
        <CardHeader>Help</CardHeader>
        <CardContent className="space-y-1">
          <CardTitle>Email support</CardTitle>
          <CardText>contact@tsjat.com</CardText>
        </CardContent>
        <CardContent className="space-y-1">
          <CardTitle>Twitter support</CardTitle>
          <a
            target="_blank"
            href="https://twitter.com/Tsjat_com"
            className="w-max"
            rel="noreferrer"
          >
            <Button icon={<div className="w-4 h-4 bg-cover bg-twitter" />}>
              Click here
            </Button>
          </a>
        </CardContent>
      </Card>
    </>
  );
};
