import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { convertToKeyword } from "scripts/convertToKeyword";
import { Button } from "stories/atoms/Button/Button";
import { ChatMessage } from "stories/atoms/ChatMessage/ChatMessage";
import { InputText } from "stories/atoms/InputText/InputText";
import { CountdownButton } from "stories/atoms/CountdownButton/CountdownButton";
import getChatMessages from "scripts/realtime database/getRTDBChatMessages";
import sendChatMessage from "scripts/realtime database/addRTDBChatMessage";
import { convertDate } from "scripts/convertDate";
import { getUserdataFromFirestore } from "scripts/firestore/GetUserdataFromFirestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "firebase.js";
import getSenderName from "scripts/firestore/getSenderName";
import { Card, CardHeader } from "stories/molecules/Card/Card";
import { escapeHtml } from "scripts/escapeHtml";
import { RecentChatroomButton } from "stories/atoms/RecentChatroomButton/RecentChatroomButton";
import {
  addLastChatroomsVisited,
  getLastChatroomsVisited,
} from "scripts/lastChatroomsVisited";
import { deleteChatroomMessage } from "scripts/realtime database/deleteChatroomMessage";
import { Helmet } from "react-helmet";
export const Chatroom = () => {
  let { chatKeyword } = useParams();

  if (chatKeyword) {
    chatKeyword = convertToKeyword(chatKeyword);
    if (chatKeyword.length > 30) chatKeyword = "chat";
  } else {
    chatKeyword = "chat";
  }

  const [lastChatroomsVisited, setLastChatroomsVisited] = useState([]);

  //Get list of recently visited chatrooms
  useEffect(() => {
    addLastChatroomsVisited(chatKeyword);
    setLastChatroomsVisited(getLastChatroomsVisited());
  }, [chatKeyword]);

  const navigate = useNavigate();

  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const [message, setMessage] = useState("");

  const [user] = useAuthState(auth);

  const [senderName, setSenderName] = useState(null);
  const [senderRank, setSenderRank] = useState(null);

  useEffect(() => {
    const fetchSenderInfo = async () => {
      //Set name
      setSenderName(await getSenderName(user));

      //Set rank
      if (user) {
        // User is logged in
        const userData = await getUserdataFromFirestore(user.uid);
        setSenderRank(userData.rank);

        setDisableSendMessage(false);
      } else {
        // User is not logged in
        setSenderRank("");

        setTimeout(() => {
          setDisableSendMessage(false);
        }, 2000);
      }
    };

    fetchSenderInfo();
  }, [user]);

  const [disableSendMessage, setDisableSendMessage] = useState(true);
  const [sendMessageOnCooldown, setSendMessageOnCooldown] = useState(false);
  const [sendMessageCountdown, setSendMessageCountdown] = useState(0);

  const startMessageCooldown = () => {
    setSendMessageOnCooldown(true);
    setSendMessageCountdown(3);

    const intervalId = setInterval(() => {
      setSendMessageCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(intervalId);
      setSendMessageOnCooldown(false);
    }, 3000);
  };

  const [loadedMessagesAmount, setLoadedMessagesAmount] = useState(10);

  const [chatMessages, setChatMessages] = useState([]);

  const messagesEndRef = useRef(null);

  const chatContainerRef = useRef(null);

  const sendMessage = async () => {
    if (
      sendMessageOnCooldown ||
      disableSendMessage ||
      message.length > 500 ||
      message.length === 0
    )
      return;

    sendChatMessage(chatKeyword, escapeHtml(message), senderName, senderRank);

    setMessage("");
    startMessageCooldown();
  };

  const scrollToBottom = () => {
    // const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
    // const scrollPosition = scrollTop / (scrollHeight - clientHeight);

    messagesEndRef.current?.scrollIntoView({
      block: "nearest",
      inline: "nearest",
      behavior: "smooth",
    });
  };

  //Collect chat messages
  useEffect(() => {
    const cleanup = getChatMessages(
      chatKeyword,
      loadedMessagesAmount,
      setChatMessages,
      setIsPageLoaded
    );

    return cleanup;
  }, [chatKeyword, loadedMessagesAmount]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(scrollToBottom, [chatMessages]);

  const ReplyToMessage = (user) => {
    if (message.includes(`@${user}`)) return;
    setMessage(`@${user} ${message}`);
  };

  const isAReply = (message) => {
    return message.includes(`${senderName}`);
  };

  return (
    <>
      <Helmet>
        <title>Tsjat | {chatKeyword} - chatroom</title>
        <meta
          name="keywords"
          content={`Tsjat, chatroom, live, live chatroom, ${chatKeyword}, no registration needed, no register, anonymous, guest, free`}
        />
        <meta
          name="description"
          content={`"${chatKeyword}" live chatroom. Text with anyone on the internet in this live chatroom. No registration needed`}
        />
      </Helmet>
      <Card grow className="mb-0">
        <CardHeader className="flex-col space-y-2">
          <div>{chatKeyword}</div>
          <div className="flex text-base overflow-hidden space-x-2">
            {lastChatroomsVisited.map((chatroom) => (
              <RecentChatroomButton
                key={chatroom}
                onClick={() => navigate(`/chatroom/${chatroom}`)}
              >
                {chatroom}
              </RecentChatroomButton>
            ))}
          </div>
        </CardHeader>
        <div className="flex flex-col justify-between grow bg-brand-2bg p-4 rounded-lg">
          <div ref={chatContainerRef} className="h-[440px] overflow-auto">
            {chatMessages.length === 0 && isPageLoaded && <EmptyChatroom />}
            {!isPageLoaded && <LoadingChatroom />}

            {chatMessages.length >= 10 && (
              <LoadMoreMessagesButton
                onClick={() =>
                  loadedMessagesAmount < 50 &&
                  setLoadedMessagesAmount(loadedMessagesAmount + 10)
                }
              />
            )}

            {chatMessages.map((message) => {
              return (
                <ChatMessage
                  key={message.datesent}
                  date={convertDate(message.datesent)}
                  badge={message.rank}
                  username={message.sender}
                  reply={isAReply(message.message)}
                  addDeleteButton={
                    senderRank === "admin" || senderRank === "mod"
                  }
                  onDelete={() =>
                    deleteChatroomMessage(user.uid, chatKeyword, message.key)
                  }
                  onReply={() => ReplyToMessage(message.sender)}
                >
                  {message.message}
                </ChatMessage>
              );
            })}

            <div ref={messagesEndRef} />
          </div>
          <div className="flex 2sm:flex-col mt-2 ">
            <div className="grow">
              <InputText
                placeholder="Write a message"
                value={message}
                onChange={(value) => setMessage(value)}
                onEnter={() => sendMessage()}
                icon="notepad"
                type={
                  sendMessageOnCooldown || message.length > 500
                    ? "error"
                    : "browse"
                }
                rightText={`${message.length}/500`}
                leftText={sendMessageOnCooldown && `${sendMessageCountdown}s`}
              />
            </div>
            <div className="ml-2 2sm:ml-0 2sm:mt-1">
              <Button onClick={() => sendMessage()} expand>
                Send
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

const EmptyChatroom = () => {
  return (
    <>
      <div className="flex flex-col overflow-x-hidden h-full justify-center items-center text-center text-white font-roboto">
        <div className="p-2 md:space-y-0 space-y-2 [&>p]:drop-shadow-text">
          <p className="md:text-3xl 4sm: text-6xl font-extrabold text-brand-contrast">
            EMPTY CHATROOM!
          </p>
          <p className="md:text-base text-brand-text text-xl">
            This chatroom is empty. Be the first one to send a message or join a
            new one below
          </p>
        </div>
      </div>
    </>
  );
};

const LoadingChatroom = () => {
  return (
    <>
      <div className="flex flex-col overflow-x-hidden h-full justify-center items-center text-center text-white font-roboto">
        <div className="absolute w-48 h-48 bg-loading" />
      </div>
    </>
  );
};

const LoadMoreMessagesButton = ({ onClick }) => {
  return (
    <div className="flex justify-center mb-2">
      <CountdownButton size="small" onClick={() => onClick()}>
        Load more
      </CountdownButton>
    </div>
  );
};
