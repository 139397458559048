import { seedNumber } from "scripts/seededNumber";
import { getUserdataFromFirestore } from "./GetUserdataFromFirestore";
import { getGuestId } from "scripts/assignGuestIDCookie";

export default async function getSenderName(user) {
  let sender = `Guest-${seedNumber(getGuestId())}`;

  async function fetchData() {
    if (user) {
      const username = await getUserdataFromFirestore(user.uid);
      sender = username.username;
    }
  }
  await fetchData();
  return sender;
}
