export function convertToKeyword(str) {
  if (typeof str !== "string") {
    throw new Error("Input must be a string");
  }

  function removeSpecialCharacters(inputString) {
    // Use a regular expression to replace everything except A-Z, a-z, 0-9, and hyphens
    const cleanedString = inputString.replace(/[^a-zA-Z0-9-]/g, "");
    return cleanedString;
  }

  let keyword = str;

  // Convert to lowercase
  keyword = keyword.toLowerCase();

  // Replace multiple spaces with a single space
  keyword = keyword.replace(/\s+/g, " ");

  // Replace spaces with hyphens
  keyword = keyword.replace(/\s/g, "-");

  keyword = removeSpecialCharacters(keyword);

  if (keyword.endsWith("-")) keyword = keyword.slice(0, -1);

  return keyword;
}
