import classNames from "classnames";
import React from "react";

export const Card = ({ children, grow = false, className = "" }) => {
  return (
    <div className="flex w-full justify-center">
      <div
        className={classNames(
          { "w-full grow": grow },
          "m-16 text-brand-text 3sm:m-0 3sm:rounded-none shadow-md space-y-4 bg-brand-bg p-8 rounded-lg min-w-[200px] w-[500px] font-roboto",
          className
        )}
      >
        {children}
      </div>
    </div>
  );
};

export const CardHeader = ({ children, className = "" }) => {
  return (
    <div
      className={classNames(
        "flex font-bold bg-brand-3bg p-4 rounded-lg overflow-hidden text-4xl text-brand-text mb-4",
        className
      )}
    >
      {children}
    </div>
  );
};

export const CardContent = ({ children, className = "" }) => {
  return (
    <div
      className={classNames(
        "flex flex-col bg-brand-2bg p-4 rounded-lg",
        className
      )}
    >
      {children}
    </div>
  );
};

export const CardTitle = ({ children, className = "" }) => {
  return (
    <div
      className={classNames(
        "flex text-4xl font-bold items-center drop-shadow-text",
        className
      )}
    >
      {children}
    </div>
  );
};
export const CardText = ({ children, className = "" }) => {
  return (
    <div
      className={classNames(
        "drop-shadow-text text-xl text-gray-200",
        className
      )}
    >
      {children}
    </div>
  );
};
