import classNames from "classnames";
import React from "react";

export const Badge = ({
  rank = "premium",
  addMargin = true,
  className = "",
}) => {
  return (
    <span
      className={classNames(
        {
          "!bg-amber-600": rank === "premium",
          "!bg-blue-700": rank === "mod",
          "!bg-red-800": rank === "admin",
          "mr-1": addMargin,
        },
        "text-white inline-flex justify-center items-center p-1 px-2 shadow-md text-sm h-max font-bold rounded-full bg-gray-800 w-max",
        className
      )}
    >
      <span className="drop-shadow-text">{rank.toUpperCase()}</span>
    </span>
  );
};
