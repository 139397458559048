import React from "react";
import classNames from "classnames";

export const InputText = ({
  placeholder = "Placeholder",
  type = "",
  attType = "text",
  label = "",
  leftText = "",
  rightText = "",
  icon = "enter",
  value = "",
  onChange = (string) => {},
  onEnter = () => {},
}) => {
  const handlePressEnter = (event) => {
    if (event.key === "Enter") {
      onEnter();
    }
  };

  return (
    <>
      <div className={`flex flex-col ${label && "space-y-1"}`}>
        <p className="text-white text-xl font-semibold overflow-hidden">
          {label}
        </p>
        <input
          autoComplete="off"
          list="autocompleteOff"
          aria-autocomplete="none"
          onKeyDown={handlePressEnter}
          className={classNames(
            {
              "!outline-red-600 !outline-2": type === "error",
              "focus-within:outline-brand-contrast focus-within:outline-2":
                type === "browse",
              "focus-within:outline-gray-800 focus-within:outline-2":
                type === "",
              "bg-enter": icon === "enter",
              "bg-notepad": icon === "notepad",
            },
            "rounded-md text-black outline-offset-0 outline outline-1 outline-gray-400 shadow-md font-roboto bg-[center_left_0.5rem] bg-[length:25px_25px] bg-no-repeat p-3 pl-11"
          )}
          type={attType}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
        />
        <div
          className={classNames(
            { "!text-red-600": type === "error" },
            "flex justify-between text-white"
          )}
        >
          <p>{leftText}</p>
          <p>{rightText}</p>
        </div>
      </div>
    </>
  );
};
